<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <div class="card-header header-elements-inline">
        <legend class="font-weight-semibold text-uppercase font-size-sm">
          <i class="icon-file-text2"></i>
          Enter Academic Year Information
        </legend>

        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>

      </div>

      <div class="card-body">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input type="text" id="txtname" class="form-control" placeholder="Academic Name" v-if="academicyear" v-model="academicyear.name" autocomplete="off">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">From:</label>
          <div class="col-md-4">
            <input type="date" class="form-control" v-if="academicyear" v-model="academicyear.from" autocomplete="off" @change="from_date_change">
          </div>

          <label class="col-md-2 col-form-label">Upto:</label>
          <div class="col-md-4">
            <input type="date" class="form-control" v-if="academicyear" v-model="academicyear.upto"  :min="academicyear.from" autocomplete="off">
          </div>
        </div>


        <div class="text-right">
          <button type="button" class="btn btn-outline border-success-600" @click="saveDocument">Save <i class="icon-paperplane ml-2"></i></button>
        </div>

      </div>
    </div>
  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import moment from 'moment/moment'

export default {
  name: 'AcademicYearForm',
  data () {
    return {
      academicyear: JSON.parse('{"id":0,"status":"Active","name":"","from":"2000-01-01","upto":"2000-01-01"}')
    }
  },
  store,
  component: {

  },
  props: {
    myacademicyear: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"status":"Active","name":"","from":"2000-01-01","upto":"2000-01-01"}')
    }
  },
  beforeMount () {
    this.academicyear = this.myacademicyear; // save props data to itself's data and deal with it
  },
  created () {
    this.$data.academicyear = JSON.parse('{"id":0,"status":"Active","name":"","from":"2000-01-01","upto":"2000-01-01"}')
  },
  mounted () {
    const self = this;

    self.academicyear.from = moment().format('YYYY-MM-DD');
    self.academicyear.upto = moment().add(1,'year').add(-1,'day').format('YYYY-MM-DD');
    $('#txtname').focus();
  },
  methods: {
    closeWindow () {
      this.$emit('academicyear_window_closed');
    },
    from_date_change () {
      const self = this;
      self.academicyear.upto = moment(self.academicyear.from).add(1,'year').add(-1,'day').format('YYYY-MM-DD');
    },
    saveDocument () {
      const self = this;

      console.log(JSON.stringify(self.$data.academicyear));

      if (self.$data.academicyear.name.trim().length == 0) {
        alert('Invalid Name');
        return;
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      const requestOptions = {
        method: (self.$data.academicyear.id == 0 ? 'POST' : 'PUT'),
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.academicyear)
      }

      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/academicyear`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          swal({ title: 'Success!', text: resp.msg, type: 'success' , onClose: () => { $('#txtname').focus()}, timer:1500 });
          self.academicyear = JSON.parse('{"id":0,"status":"Active","name":"","from":"2000-01-01","upto":"2000-01-01"}');
          self.$emit('academicyear_saved',resp.data);
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' , onClose: () => { $('#txtname').focus()}, timer:3000 });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' , onClose: () => { $('#txtname').focus()}, timer:3000 });
      }).finally(()=>{
        $('#mycard').unblock();
      });

    }
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
